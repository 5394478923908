<template>
  <div>
    <Modal :options="{width: '40vw'}" @close="$emit('close')">
      <form @submit.prevent="cargar_documento">
        <div class="title">Cargar documento</div>
        <div class="body">
          <div class="row form-group">
            <label for="archivo_seleccionado" class="col-form-label col-sm-3">Documento</label>
            <div class="col-sm-9">
              <div class="input-group mb-3">
                <div class="form-control text-truncate" aria-label="Recipient's username" aria-describedby="seleccionar_documento">{{ nombre_archivo }}</div>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" id="seleccionar_documento" @click="$refs.seleccionar_archivo.click()">Seleccionar</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <label for="etiqueta_archivo" class="col-form-label col-sm-3">Etiqueta</label>
            <div class="col-sm-9">
              <select v-model="etiqueta_archivo" name="etiqueta_archivo" id="etiqueta_archivo" class="form-control">
                <option v-for="opcion in catalogo.opciones" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }}</option>
              </select>
            </div>
          </div>
          <input class="seleccionar_archivo" ref="seleccionar_archivo" type="file" name="seleccionar_archivo" id="seleccionar_archivo" @change="detectar_archivo">

          <div class="row form-group">
            <div class="col-sm-12 text-right"><button class="btn btn-primary" type="button" @click="agregar_documento">Agregar documento</button></div>
          </div>

          <table class="listar_documentos">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Tipo</th>
                <th>Peso</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(archivo,index) in archivos">
                <td>{{ archivo.archivo.name }}</td>
                <td>{{ buscar_nombre(archivo.tipo) }}</td>
                <td>{{ $helper.bytesConverted(archivo.archivo.size) }}</td>
                <td>
                  <img class="mr-2" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar archivo" title="Editar archivo" @click="editar_archivo(index)">
                  <img src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar archivo" title="Elimitar archivo" @click="quitar_archivo(index)">
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div class="footer">
          <div class="roW">
            <div class="col-sm-12 text-right"><button class="btn btn-primary mr-2">Cargar</button><button class="btn btn-danger" type="button" @click="$emit('close')">Cancelar</button></div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'
  import apiValija from '@/apps/formalizacion/api/v2/valija'

  export default {
    components: {
      Modal
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            solicitud: null
          }
        }
      }
    }
    ,data() {
      return {
        nombre_archivo: null
        ,etiqueta_archivo: 'na'
        ,catalogo: {
          opciones: []
        }
        ,archivos: []
        ,editar_archivo_index: null
      }
    }
    ,mounted() {
      this.obtener_catalogo();
    }
    ,methods: {
      async obtener_catalogo() {
        try {
          this.catalogo = (await apiValija.obtener_catalogo('tipos_archivos')).data
          this.catalogo.opciones.unshift({id:'x',valor:'na',nombre:'Selecciona un archivo...'});
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,detectar_archivo(e) {
        if (this.$refs.seleccionar_archivo.files.length == 0) {
          this.nombre_archivo = null;
          return;
        }

        this.nombre_archivo = this.$refs.seleccionar_archivo.files[0].name;
      }
      ,agregar_documento() {
        if (!this.editar_archivo_index && this.$refs.seleccionar_archivo.files.length == 0)
            return this.$helper.showMessage('Error','Debes seleccionar al menos un archivo','error','alert');

        if (this.etiqueta_archivo == 'na')
          return this.$helper.showMessage('Error','Debes seleccionar la etiquita correcta','error','alert');

        if (!this.editar_archivo_index) {
          this.archivos.push({
            archivo: this.$refs.seleccionar_archivo.files[0]
            ,tipo: this.etiqueta_archivo
          });
        }else {
          if (this.$refs.seleccionar_archivo.files.length > 0) {
            if (this.archivos[this.editar_archivo_index].archivo.name != this.$refs.seleccionar_archivo.files[0].name)
              this.archivos[this.editar_archivo_index].archivo = this.$refs.seleccionar_archivo.files[0];
          }

          this.archivos[this.editar_archivo_index].tipo = this.etiqueta_archivo;
        }

        this.$refs.seleccionar_archivo.value = '';
        this.nombre_archivo = null;
        this.etiqueta_archivo = 'na';
      }
      ,async cargar_documento() {
        try {
          let formData = new FormData();

          for(let i=0; i<this.archivos.length; i++) {
            formData.append('archivos['+i+'][archivo]', this.archivos[i].archivo);
            formData.append('archivos['+i+'][tipo]', this.archivos[i].tipo);
          }

          let res = (await apiSolicitud.cargar_documentos(this.solicitud.id, formData)).data;
          this.$log.info('res', res);

          this.$helper.showMessage('Echo!','Se subieron correctamente los documentos, pueden tardar unos minutos en aparecer, siempre puedes recargar los documentos con el botón "Recargar documentos"','success','alert');

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,buscar_nombre(tipo) {
        let tipo_archivo = null;

        for(let i=0; i<this.catalogo.opciones.length; i++) {
          if (tipo == this.catalogo.opciones[i].valor)
            tipo_archivo = this.catalogo.opciones[i];
        }

        return tipo_archivo.nombre;
      }
      ,mejorar_peso_archivos: function(bytes) {
        if (bytes >= 1000000000)
          return Math.ceil(((bytes / 1000) / 1000) / 1000)+'gb';

        if (bytes >= 1000000)
          return Math.ceil((bytes / 1000) / 1000)+'mb';

        if (bytes > 1000)
          return Math.ceil(bytes / 1000)+'kb';

        return bytes+'bytes';
      }
      ,editar_archivo(index) {
        let archivo = null;

        for(let i=0; i<this.archivos.length; i++) {
          if (i == index)
            archivo = this.archivos[i];
        }

        if (!archivo)
          return this.$helper.showMessage('Error','El archivo a editar no existe','error','alert');

        this.nombre_archivo = archivo.archivo.name;
        this.etiqueta_archivo = archivo.tipo;
        this.editar_archivo_index = index;
      }
      ,quitar_archivo(index) {
        let archivos = [];

        for(let i=0; i<this.archivos.length; i++) {
          if (i != index)
            archivos.push(this.archivos[i]);
        }

        this.archivos = archivos;
      }
    }
  }
</script>

<style lang="scss">
  .seleccionar_archivo {
    display: none;
  }

  .listar_documentos {
    width: 100%;

    thead {
      tr {
        background-color: #4C4C4C;
        color: #fff;

        th {
          padding: 5px 10px;
        }
      }
    }

    tbody {
      tr {
        td:nth-child(4) {
          text-align: center !important;

          img {
            width: 15px;
            display:inline-block;
          }
        }
      }
    }
  }
</style>